import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { careseekerlistinfo } from "../Redux/Action/CareSeekerAction";
import { sendNotification } from "../Redux/Action/Notification";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from "react-toastify";

export default function Notification() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(careseekerlistinfo());
  }, []);

  const userList = useSelector((state) => state.careseeker.careseekerlistData);

  const [selected, setSelected] = useState([]);
  const [message, setMessage] = useState([]);

  const sendMessage = () => {
    let userId = selected?.map(function (object) {
      return object.value;
    });

    if (userId?.length > 0 && message?.length > 0) {
      dispatch(
        sendNotification({
          userIds: userId,
          content: message,
        })
      ).then(function () {
        setSelected([]);
        setMessage("");
      });
    } else {
      toast.error("All fields are required");
    }
  };
  console.log(userList, "userListuserList");

  return (
    <Layout>
      <div className="dashboard_data_wrap">
        <div className="dashbord_cont_wrapper">
          <div className="profile_details_header">Notification</div>
          <div className="notification-wrap">
            <div className="notification-heading">
              <p>Select user : </p>
              <div className="form-group">
                <MultiSelect
                  options={userList?.map((object, i) => ({
                    label: `${object?.first_name} ${object?.last_name}`, // Displayed text
                    value: object?._id || i, // Use object id or index as value
                  }))}
                  value={selected}
                  onChange={setSelected}
                  labelledBy={"Select"}
                  isCreatable={true}
                />
              </div>
            </div>
            <div className="notification-form-wrap">
              <div className="form-group">
                <label> Notification Content :</label>
                <textarea
                  className="form-control"
                  placeholder="Write here.."
                  value={message}
                  onChange={(e) => {
                    if (message?.length <= 400) {
                      setMessage(e.target.value);
                    }
                  }}
                ></textarea>
              </div>
              <span className="character-limit">400 character limit </span>
              <button className="common-btn" onClick={sendMessage}>
                SEND
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

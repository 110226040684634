import React from "react";
import logo from "../assets/images/Buddy-4-Travel_logo.png";
import logo1 from "../assets/images/Food_1.png";
import logo2 from "../assets/images/Food_2.jpg";
import logotravel from "../assets/images/Logo-travel.png";
import { NavLink } from "react-router-dom";
import logo3 from "../assets/images/Group 418323.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { forgetpassword, Login } from "../Redux/Action/AuthAction";

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("Email is required"),
    }),
    onSubmit: (value) => {
      value.url = "https://buddyadmin.b4t.app/reset-password/";
      dispatch(forgetpassword(value));
    },
  });
  return (
    <div className="background loginpage-wrap">
      <div className="containerbx">
        <div className="right-section">
          <h1 style={{ textAlign: "center" }}>Forget Password</h1>

          <form className="login-form" onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Enter email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                name="email"
              />
              {formik.touched.email &&
                formik.submitCount > 0 &&
                formik.errors.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
            </div>
            <button type="submit">Continue</button>
          </form>
        </div>
      </div>
    </div>
  );
}

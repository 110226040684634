import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import usericon from "../assets/images/80182253575c97311b5130e11f6441fc.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  adminupload,
  getDocument,
  getprofiledetail,
  updatestaus,
} from "../Redux/Action/CareSeekerAction";
import DeleteModal from "../Component/DeleteModal";
import BlockModal from "../Component/BlockModal";
import Flag from "react-flagkit";
import Contry from "../Component/Contry";

export default function Profiledetails() {
  const [id_proof, setid_proof] = useState(null);
  const [document, setDocument] = useState(null);
  const [address_proof, setaddress_proof] = useState(null);
  const [infoid, setInfoId] = useState(null);
  const [show, setShow] = useState(false);
  const [showblock, setShowblock] = useState(false);
  const [title, settitle] = useState(false);

  const [type, setType] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleidproofUpload = (event, idinfo) => {
    const file = event.target.files[0];
    if (file) {
      let fileinfo = new FormData();
      fileinfo.append("file", file);
      dispatch(adminupload(fileinfo)).then(function (info) {
        let id_proof = {
          id: idinfo,
          picture: info.payload.url,
        };

        dispatch(updatestaus({ userId: id, id_proof: id_proof })).then(
          function () {
            setid_proof(info.payload.url);
            dispatch(getprofiledetail({ id }));
          }
        );
      });
    }
  };

  const handleaddressproofUpload = (event, idinfo) => {
    const file = event.target.files[0];

    if (file) {
      let fileinfo = new FormData();
      fileinfo.append("file", file);
      dispatch(adminupload(fileinfo)).then(function (info) {
        let address_proof = {
          id: idinfo,
          picture: info.payload.url,
        };
        dispatch(
          updatestaus({ userId: id, address_proof: address_proof })
        ).then(function () {
          dispatch(getprofiledetail({ id }));

          setaddress_proof(info.payload.url);
        });
      });
    }
  };

  useEffect(() => {
    dispatch(getprofiledetail({ id }));
    dispatch(getDocument()).then(function (info) {
      setDocument(info.payload);
    });
  }, [id]);
  const getprofiledetails = useSelector(
    (state) => state.careseeker.getprofiledetail
  );
  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // If the current month is before the birth month, or if it's the same month but the day hasn't passed yet
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return isNaN(age) == true ? "N/A" : age;
  }
  const handleDelete = () => {
    let address_proof = {
      id: infoid,
      picture: "https://imgbb.com/",
    };

    dispatch(
      type == "Passport"
        ? updatestaus({ userId: id, id_proof: address_proof })
        : updatestaus({ userId: id, address_proof: address_proof })
    ).then(function () {
      type == "Passport" ? setid_proof(null) : setaddress_proof(null);
      setShow(false);
      dispatch(getprofiledetail({ id }));
    });
  };

  const handleBlockActive = () => {
    dispatch(
      updatestaus({
        userId: getprofiledetails._id,
        is_active: getprofiledetails.is_active == true ? false : true,
      })
    ).then(function () {
      dispatch(getprofiledetail({ id }));
      setShowblock(false);
    });
  };

  return (
    <div>
      {" "}
      <Layout>
        <div className="dashboard_data_wrap">
          <div className="dashbord_cont_wrapper">
            <div className="user_list_wrap table-responsive">
              <div className="header-style">
                <div className="profile_details_header">Personal Details</div>
                <div className="d-flex">
                  <div className="blockbtnbcx">
                    <div
                      className="profile_active_button blckbtn"
                      style={{
                        backgroundColor:
                          getprofiledetails?.is_active == true
                            ? "#FFC600"
                            : "#17C470",
                      }}
                      onClick={() => {
                        setShowblock(true);
                        settitle(
                          getprofiledetails?.is_active == true
                            ? "Block"
                            : "Active"
                        );
                      }}
                    >
                      {getprofiledetails?.is_active == true
                        ? "Block"
                        : "Active"}
                    </div>
                  </div>{" "}
                  {getprofiledetails?.is_active == true ? (
                    <div className="profile_active_button activebtn">
                      Active
                    </div>
                  ) : (
                    <div className="profile_active_button activebtn">
                      Blocked
                    </div>
                  )}
                </div>
              </div>
              <div className="profile_details">
                <div className="profileimg-bcx">
                  <div className="profile-image">
                    <img
                      src={getprofiledetails?.profile_picture}
                      alt="prifile"
                      className="profile_pic"
                    ></img>
                  </div>
                </div>
                <div className="profile-info-bcx pro-info">
                  <div className="col-5 pro-info">
                    {" "}
                    <ul>
                      <li>Full Name:</li>
                      <li>Email ID :</li>
                      <li>Mobile:</li>
                      <li>Age:</li>
                      <li>Gender:</li>
                      <li>Medical Issue:</li>
                      <li>Profession:</li>
                      <li>Language:</li>
                      {/* <li>
                        Selected Preferred <br></br> Buddy Gender :
                      </li> */}
                    </ul>
                  </div>

                  <div className="col-7">
                    <ul>
                      <li>
                        {getprofiledetails?.first_name +
                          " " +
                          getprofiledetails?.last_name}
                      </li>
                      <li>{getprofiledetails?.email}</li>
                      <li>
                        {" "}
                        {getprofiledetails?.phone?.country_code !=
                          undefined && (
                          <Flag
                            country={Contry(
                              getprofiledetails?.phone?.country_code
                            )}
                            size={20}
                          />
                        )}{" "}
                        {getprofiledetails?.phone?.country_code +
                          getprofiledetails?.phone?.number}
                      </li>
                      <li>{calculateAge(getprofiledetails?.date_of_birth)}</li>
                      <li>{getprofiledetails?.gender}</li>
                      <li>{getprofiledetails?.medical_issue?.name}</li>
                      <li>{getprofiledetails?.profession?.name}</li>
                      <li>
                        {getprofiledetails.language_preferences?.join(", ")}
                      </li>
                      {/* <li>Jitendra kumar</li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="flexdivbcx">
                <div className="flexdiv">
                  {document?.map(function (object) {
                    if (object.name == "proof of address")
                      return (
                        <>
                          <label className="upload_document">
                            {object.name}
                          </label>
                          <div className="header-style">
                            <div className="uploaded-documents">
                              <div className="document-item">
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="file-input"
                                  onChange={(e) => {
                                    handleaddressproofUpload(e, object?._id);
                                  }}
                                />

                                <span>
                                  {address_proof != null
                                    ? "Uploaded Image"
                                    : getprofiledetails?.address_proof
                                        ?.picture != "https://imgbb.com/"
                                    ? "Uploaded Image"
                                    : "No Document"}
                                </span>
                                <div className="document-actions">
                                  {getprofiledetails?.address_proof?.picture !=
                                  "https://imgbb.com/" ? (
                                    <i
                                      className="fas fa-eye"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upload-preview-modal2"
                                    ></i>
                                  ) : (
                                    <i className="fas fa-eye"></i>
                                  )}
                                  <i
                                    className="fas fa-trash ml-2"
                                    onClick={() => {
                                      setShow(true);
                                      setInfoId(object?._id);
                                      setType("Address");
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                  })}
                </div>
                <div className="flexdiv">
                  {document?.map(function (object) {
                    if (object.name == "passport")
                      return (
                        <>
                          <label className="upload_document">
                            {object.name}
                          </label>

                          <div className="header-style">
                            <div className="uploaded-documents">
                              <div className="document-item">
                                <input
                                  type="file"
                                  accept="image/*"
                                  className="file-input"
                                  onChange={(e) => {
                                    handleidproofUpload(e, object?._id);
                                  }}
                                />

                                <span>
                                  {id_proof != null
                                    ? "Uploaded Image"
                                    : getprofiledetails?.id_proof?.picture !=
                                      "https://imgbb.com/"
                                    ? "Uploaded Image"
                                    : "No Document"}{" "}
                                </span>
                                <div className="document-actions">
                                  {getprofiledetails?.id_proof?.picture !=
                                  "https://imgbb.com/" ? (
                                    <i
                                      className="fas fa-eye"
                                      data-bs-toggle="modal"
                                      data-bs-target="#upload-preview-modal1"
                                    ></i>
                                  ) : (
                                    <i className="fas fa-eye"></i>
                                  )}

                                  <i
                                    className="fas fa-trash ml-2"
                                    onClick={() => {
                                      setShow(true);
                                      setInfoId(object?._id);
                                      setType("Passport");
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                  })}
                </div>
              </div>
            </div>
          </div>
          <DeleteModal
            show={show}
            handleClose={() => {
              setShow(false);
            }}
            handleDelete={handleDelete}
          />
          <BlockModal
            show={showblock}
            title={title}
            handleClose={() => {
              setShowblock(false);
            }}
            handleBlockActive={handleBlockActive}
          />
        </div>
      </Layout>
      {/* Upload Preview Modal Start Here */}
      <div
        className="modal fade"
        id={"upload-preview-modal1"}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Upload Preview Passport
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="upload-preive-wrap">
                <img
                  src={
                    getprofiledetails?.id_proof?.picture
                      ? getprofiledetails.id_proof?.picture
                      : id_proof
                  }
                  alt="preview-image"
                  style={{
                    height: "357px",
                    width: "400px",
                  }}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id={"upload-preview-modal2"}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Upload Preview Proof Of Address
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="upload-preive-wrap">
                <img
                  src={getprofiledetails?.address_proof?.picture}
                  style={{
                    height: "357px",
                    width: "400px",
                  }}
                  alt="preview-image"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Upload Preview Modal End Here */}
      {/* Remove Upload Modal End Here */}
      <div
        className="modal fade"
        id="upload-remove-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Remove Upload
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="upload-preive-wrap">
                <p>Are you want to remove this upload</p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Remove Upload Modal End Here */}
    </div>
  );
}

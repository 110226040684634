import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Addpromotion,
  deletePromotion,
  GetPromotion,
} from "../Redux/Action/PromoCode";
import { adminupload } from "../Redux/Action/CareSeekerAction";
import LoadingOverlay from "react-loading-overlay";
import {
  createupdatecommission,
  getcommission,
} from "../Redux/Action/Commission";
export default function Commission() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true); // Loader state

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formik = useFormik({
    initialValues: {
      carer_commission: "",
      admin_commission: "",
      fare_percentage: "",
    },
    validationSchema: Yup.object().shape({
      fare_percentage: Yup.string().required("Fare Percentage is required"),
      carer_commission: Yup.string().required("Carer Commission is required"),
      admin_commission: Yup.string().required("Admin Commission required"),
    }),
    onSubmit: (value) => {
      value.id = id;
      dispatch(createupdatecommission(value)).then(function () {
        dispatch(getcommission()).then(() => setLoading(false));
        handleClose();
        formik.resetForm();
      });
    },
  });

  //Fetch the promotion

  useEffect(() => {
    dispatch(getcommission()).then(() => setLoading(false)); // Stop loader after data is fetched
  }, []);
  const commissionlist = useSelector((state) => state.Commission.commission);

  return (
    <LoadingOverlay active={loading} text="Loading data...">
      <Layout>
        <div className="dashboard_data_wrap">
          <div className="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box"></div>
              <div class="export-options">
                <button className="common-btn" onClick={handleShow}>
                  +Add
                </button>
              </div>
            </div>

            <div className="user_list_wrap table-responsive">
              <table className="table table-stripd">
                <thead>
                  <th>Fare Percentage</th>
                  <th>Admin Commission</th>
                  <th>Carer Commission</th>
                  <th>Edit</th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {commissionlist?.map(function (object) {
                    return (
                      <tr>
                        <td>{object?.fare_percentage}</td>

                        <td>{object?.admin_commission}</td>
                        <td>{object?.carer_commission}</td>
                        <td>
                          {" "}
                          <div className="iconsbcx">
                            <div
                              className="common-btn-assign"
                              onClick={() => {
                                setShow(true);
                                setId(object?._id);
                                formik.setFieldValue(
                                  "admin_commission",
                                  object?.admin_commission
                                );
                                formik.setFieldValue(
                                  "fare_percentage",
                                  object?.fare_percentage
                                );
                                formik.setFieldValue(
                                  "carer_commission",
                                  object?.carer_commission
                                );
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="chekcbcx">
                          <label className="switch">
                            <input type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{id == "" ? "Add" : "Update"} Commission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              {/* Banner Image Field */}

              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Fare Percentage
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter fare percentage"
                  name="fare_percentage"
                  onChange={formik.handleChange}
                  value={formik.values.fare_percentage}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.fare_percentage &&
                  formik.submitCount > 0 &&
                  formik.errors.fare_percentage && (
                    <div className="error text-danger">
                      {formik.errors.fare_percentage}
                    </div>
                  )}
              </div>
              {/* Flat Discount Rate Field */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Admin Commission
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter Admin commission"
                  name="admin_commission"
                  onChange={formik.handleChange}
                  value={formik.values.admin_commission}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.admin_commission &&
                  formik.submitCount > 0 &&
                  formik.errors.admin_commission && (
                    <div className="error text-danger">
                      {formik.errors.admin_commission}
                    </div>
                  )}
              </div>

              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Carer Commission
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter Carer Commission"
                  name="carer_commission"
                  onChange={formik.handleChange}
                  value={formik.values.carer_commission}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.carer_commission &&
                  formik.submitCount > 0 &&
                  formik.errors.carer_commission && (
                    <div className="error text-danger">
                      {formik.errors.carer_commission}
                    </div>
                  )}
              </div>

              <div className="modal-footer">
                {/* Submit Button */}
                <button type="submit" className="common-btn-assign">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Layout>
    </LoadingOverlay>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import {
  careseekerlist,
  careseekerlistinfo,
  getprofiledetail,
} from "../Action/CareSeekerAction";

const CareSeeker = createSlice({
  name: "CareSeeker",
  initialState: {
    careseekerArr: [],
    careseekerlistData: [],
    getprofiledetail: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(careseekerlist.fulfilled, (state, action) => {
        state.careseekerArr = action.payload;
      })

      .addCase(careseekerlistinfo.fulfilled, (state, action) => {
        state.careseekerlistData = action.payload;
      })

      .addCase(getprofiledetail.fulfilled, (state, action) => {
        state.getprofiledetail = action.payload;
      });
  },
});

export default CareSeeker.reducer;

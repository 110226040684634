import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import usericon from "../assets/images/admin_user_pic.jpg";
import { useDispatch, useSelector } from "react-redux";
import { careseekerlist, updatestaus } from "../Redux/Action/CareSeekerAction";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import ReactPaginate from "react-paginate";
import DeleteModal from "../Component/DeleteModal";
import Pagination from "../Component/Pagination";
import Contry from "../Component/Contry";
import Flag from "react-flagkit";
import { userexport } from "../Redux/Action/SubAdmin";
import useFirstLetterCapital from "../CustomHooks/FirstLetterCapital";
import FirstLetterCapital from "../CustomHooks/FirstLetterCapital";

export default function CareSeeker() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true); // Loader state
  const [show, setShow] = useState(false);
  const [careseekerobject, setcareseekerobject] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  useEffect(() => {
    setLoading(true); // Start loader when fetching data
    dispatch(
      careseekerlist({
        role: "care seeker",
        page: page,
        limit: limit,
      })
    ).then(() => setLoading(false)); // Stop loader after data is fetched
  }, []);

  const list = useSelector((state) => state.careseeker.careseekerArr);

  function calculateAge(dob) {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return isNaN(age) == true ? "N/A" : age;
  }

  const handleDelete = () => {
    setLoading(true); // Start loader when performing actions
    dispatch(
      updatestaus({
        userId: careseekerobject._id,
        is_deleted: true,
      })
    ).then(() => {
      setShow(false);
      dispatch(
        careseekerlist({
          role: "care seeker",
        })
      ).then(() => setLoading(false)); // Stop loader after actions are done
    });
  };

  const searchdata = () => {
    if (search != undefined) {
      setLoading(true); // Start loader when searching
      if (search === "") {
        dispatch(
          careseekerlist({
            role: "care seeker",
            limit,
            page,
          })
        ).then(() => setLoading(false)); // Stop loader after data is fetched
      } else {
        dispatch(
          careseekerlist({
            role: "care seeker",
            search: search,
            limit,
            page,
          })
        ).then(() => setLoading(false)); // Stop loader after search is done
      }
    }
  };

  const handlePageClick = (e) => {
    setPage(e.selected);
    setLoading(true);
    dispatch(
      careseekerlist({ role: "care seeker", limit, page: e.selected + 1 })
    ).then(() => setLoading(false));
  };

  const exportcsv = async () => {
    const header = [
      { id: "first_name", title: "First Name" },
      { id: "last_name", title: "Last Name" },
      { id: "email", title: "Email" },
      { id: "role", title: "Role" },
      { id: "role", title: "Role" },
    ];
    dispatch(userexport({ header, role: "care seeker" })).then(function (info) {
      window.location.href = info.payload;
    });
  };

  return (
    <LoadingOverlay active={loading} text="Loading data...">
      <Layout>
        <div className="dashboard_data_wrap">
          <div className="dashbord_cont_wrapper">
            <div className="search-container">
              <div className="search-box">
                <span>
                  <i className="fas fa-search"></i>
                </span>
                <input
                  type="text"
                  placeholder="Search here..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button className="search-button" onClick={searchdata}>
                  Search
                </button>
              </div>
              <div className="export-options">
                <label htmlFor="export1">Export as :</label>
                <select id="export1" name="export" onChange={exportcsv}>
                  <option value="csv">Please Select</option>

                  <option value="csv">Export .csv</option>
                </select>
              </div>
            </div>

            <div className="user_list_wrap table-responsive">
              <table className="table table-stripd">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.data?.map((object, i) => (
                    <tr key={i}>
                      <td>
                        <div className="d-flex align-items-center namebcx">
                          <img
                            src={object.profile_picture}
                            alt="profile"
                            width="31.33"
                            height="31.33"
                          />{" "}
                          <span>
                            {FirstLetterCapital(
                              object?.first_name + " " + object?.last_name
                            )}
                          </span>
                        </div>
                      </td>
                      <td>{object?.email}</td>
                      <td>
                        <Flag
                          country={Contry(object?.phone?.country_code)}
                          size={20}
                        />{" "}
                        {object?.phone?.country_code + object?.phone?.number}
                      </td>
                      <td>{FirstLetterCapital(object?.gender)}</td>
                      <td>{calculateAge(object?.date_of_birth)}</td>
                      <td>
                        {object?.is_active === true ? (
                          <div className="active_button">Active</div>
                        ) : (
                          <div className="active_button-block">Blocked</div>
                        )}
                      </td>
                      <td>
                        <div className="iconsbcx">
                          <i
                            className="fas fa-eye"
                            onClick={() => {
                              navigate(`/profile-details/${object?._id}`);
                            }}
                          ></i>
                          <i
                            className="fas fa-trash"
                            onClick={() => {
                              setcareseekerobject(object);
                              setShow(true);
                            }}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={list?.totalItems}
            />
          </div>
        </div>
      </Layout>
      <DeleteModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        handleDelete={handleDelete}
      />
    </LoadingOverlay>
  );
}

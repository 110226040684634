import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { toast } from "react-toastify";

export const bookinlist = createAsyncThunk("/booking-list", async (value) => {
  try {
    let info = await Api.post(`/all-booking`, value);
    if (info.data.status_code == 200) {
      return info.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const getbookingdetail = createAsyncThunk(
  "booking-details",
  async (value) => {
    try {
      let info = await Api.get(`/booking-details/${value}`);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const updatebookingcarer = createAsyncThunk(
  "carer-booking-request",
  async (value) => {
    try {
      let info = await Api.post(`/carer-booking-request`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const exportbooking = createAsyncThunk(
  "export-booking",
  async (value) => {
    try {
      let info = await Api.post(`/export-booking`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

//
export const cancelbookingrequest = createAsyncThunk(
  "cancel-booking-request",
  async (value) => {
    try {
      let info = await Api.post(`/cancel-booking-request`, value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const cancelreason = createAsyncThunk("cancel-reason", async (value) => {
  try {
    let info = await Api.get(`/cancel-reason`);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});
export const refund = createAsyncThunk("refund", async (value) => {
  try {
    let info = await Api.post(`/refund`, value);
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

//

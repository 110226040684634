import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bookinlist } from "../Redux/Action/BookingAction";
import moment from "moment";
import Pagination from "../Component/Pagination";
import FirstLetterCapital from "../CustomHooks/FirstLetterCapital";

export default function Reports() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      bookinlist({
        search: "",
        status: "assigned",
        page: 1,
        limit: 15,
      })
    );
  }, []);
  const list = useSelector((state) => state.Booking.booking);
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    dispatch(
      bookinlist({
        search: "",
        status: "assigned",
        page: e.selected + 1,
        limit: 10,
      })
    );
  };
  return (
    <Layout>
      {" "}
      <div class="dashboard_data_wrap">
        <div class="dashbord_cont_wrapper">
          <div class="user_list_wrap table-responsive">
            <table class="table table-stripd">
              <thead>
                <th>Name</th>
                <th>Date</th>
                <th>Flight No</th>

                <th>Refund</th>
                <th>Total Revenue</th>
              </thead>
              <tbody>
                {list?.data?.map(function (object, i) {
                  return (
                    <tr>
                      <td>
                        {" "}
                        {FirstLetterCapital(
                          object?.care_seeker?.first_name +
                            " " +
                            object?.care_seeker?.last_name
                        )}
                      </td>
                      <td>{moment(object?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>{object?.flight_details?.flight_number}</td>
                      <td>No</td>
                      <td>{object?.payment_details?.total_amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={list?.totalItems}
          />
        </div>
      </div>
    </Layout>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../Config/ApiConfig";
import { ToastContainer, toast } from "react-toastify";
export const Login = createAsyncThunk("login", async (value) => {
  try {
    let info = await Api.post("login", value);
    if (info.data.status_code == 200) {
      localStorage.setItem("authorization", info.data.data.token);
      window.location.href = "/dashboard";
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const createAdmin = createAsyncThunk("create-admin", async (value) => {
  try {
    let info = await Api.post("create-admin", value);
    if (info.data.status_code == 200) {
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});
//

export const deleteAdmin = createAsyncThunk("delete-admin", async (value) => {
  try {
    let info = await Api.delete(`delete-admin/${value}`);
    if (info.data.status_code == 200) {
      return info.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const updateAdmin = createAsyncThunk("update-admin", async (value) => {
  try {
    let info = await Api.post("update-admin", value);
    if (info.data.status_code == 200) {
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const getSubAdminInfo = createAsyncThunk(
  "get-sub-admin-info",
  async (id) => {
    try {
      let info = await Api.get(`get-sub-admin-info/${id}`);
      if (info.data.status_code == 200) {
        return info.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const dashboard = createAsyncThunk("udashboard", async (value) => {
  try {
    let info = await Api.get("dashboard");
    if (info.data.status_code == 200) {
      return info.data.data;
    } else {
      toast.error(info.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
});

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (value) => {
    try {
      let info = await Api.post("resetPassword", value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

export const forgetpassword = createAsyncThunk(
  "forgetpassword",
  async (value) => {
    try {
      let info = await Api.post("forgetpassword", value);
      if (info.data.status_code == 200) {
        return info.data.data;
      } else {
        toast.error(info.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
);

//get-sub-admin-info

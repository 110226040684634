import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import user from "../assets/images/userimg-bg.png";
import flag from "../assets/images/flagimg.png";
import FlightIcon from "../assets/images/flight.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getbookingdetail } from "../Redux/Action/BookingAction";
import Flag from "react-flagkit";
import Contry from "../Component/Contry";
import moment from "moment";
import { Accordion } from "react-bootstrap";
import greencircle from "../assets/images/green-circle.png";
import dotCircle from "../assets/images/dot-circle.png";
export default function BookingDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getbookingdetail(id));
  }, [id]);
  const bookinginfo = useSelector((state) => state.Booking.bookingdetails);
  return (
    <div>
      {" "}
      <Layout>
        <div className="dashboard_data_wrap">
          <div className="dashbord_cont_wrapper">
            <div className="user_list_wrap table-responsive">
              <div className="header-style">
                <div className="profile_details_header">
                  BOOKING ID : {bookinginfo?.booking_number}
                </div>
              </div>
            </div>
            <div className="bg-div">
              <p>Booking Details</p>
            </div>
            <div className="booking-infobcx">
              <h4>Care Seeker</h4>
              <div className="bookinginfo">
                <div className="userimg">
                  <img src={bookinginfo?.care_seeker?.profile_picture} />
                </div>
                <div className="userinfobx">
                  <div className="infodv">
                    <div>Full Name : </div>
                    <div>
                      {bookinginfo?.care_seeker?.first_name +
                        " " +
                        bookinginfo.care_seeker?.last_name}
                    </div>
                  </div>
                  <div className="infodv">
                    <div>Email ID : </div>
                    <div>{bookinginfo?.care_seeker?.email}</div>
                  </div>
                  <div className="infodv">
                    <div>Mobile : </div>
                    <div>
                      {bookinginfo?.care_seeker?.phone?.country_code !=
                        undefined && (
                        <Flag
                          country={Contry(
                            bookinginfo?.care_seeker?.phone?.country_code
                          )}
                          size={20}
                        />
                      )}{" "}
                      <span>
                        {" "}
                        {bookinginfo?.care_seeker?.phone?.country_code +
                          bookinginfo?.care_seeker?.phone?.number}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking-infobcx">
              <h4>Assigned Carer</h4>
              <div className="bookinginfo">
                <div className="userimg">
                  <img src={bookinginfo?.carer?.profile_picture} />
                </div>
                <div className="userinfobx">
                  <div className="infodv">
                    <div>Full Name :</div>
                    <div>
                      {bookinginfo?.carer?.first_name +
                        " " +
                        bookinginfo.carer?.last_name}
                    </div>
                  </div>
                  <div className="infodv">
                    <div>Email ID :</div>
                    <div>{bookinginfo?.carer?.email}</div>
                  </div>
                  <div className="infodv">
                    <div>Mobile :</div>
                    <div>
                      {bookinginfo?.carer?.phone?.country_code != undefined && (
                        <Flag
                          country={Contry(
                            bookinginfo?.carer?.phone?.country_code
                          )}
                          size={20}
                        />
                      )}{" "}
                      <span>
                        {" "}
                        {bookinginfo?.carer?.phone?.country_code +
                          bookinginfo?.carer?.phone?.number}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking-infobcx">
              <h4>Flight Details</h4>
              <div className="bookinginfo">
                <div className="userinfobx">
                  <div className="infodv">
                    <div className="frominfo">
                      <h5> {bookinginfo?.flight_details?.departure_airport}</h5>
                      <span>
                        {" "}
                        {bookinginfo?.flight_details?.departure_airport}
                      </span>
                    </div>{" "}
                    <div className="flgtimg">
                      <img src={FlightIcon} />
                    </div>
                    <div className="frominfo toinfo">
                      <h5>
                        {" "}
                        <h5>
                          {bookinginfo?.flight_details?.destination_airport}
                        </h5>
                        <span>
                          {bookinginfo?.flight_details?.destination_airport}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="infodv">
                    <div>
                      {moment(
                        bookinginfo?.flight_details?.departure_date
                      ).format("DD MMM YYYY")}
                      ,{" "}
                      {moment(
                        bookinginfo?.flight_details?.departure_time
                      ).format("HH:mm A")}
                    </div>
                  </div>
                  <div className="infodv">
                    <div>Flight No. : </div>
                    <div>
                      <span>{bookinginfo?.flight_details?.flight_number}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="booking-infobcx">
              <h4>Booking Status</h4>
              <div className="bookinginfo">
                <div className="statusbcx-wrap">
                  <ul>
                    <li className="statusdiv">
                      <div
                        className={
                          bookinginfo?.trailDetails?.booking_status?.pending
                            ?.status != true
                            ? "statusbx "
                            : "statusbx statuscomplete"
                        }
                      >
                        Booking Request Initiated
                      </div>
                      <span>
                        {moment(
                          bookinginfo?.trailDetails?.booking_status?.pending
                            ?.createdAt
                        ).format("DD MMMM h:m")}
                      </span>
                    </li>
                    <li className="statusdiv">
                      <div
                        className={
                          bookinginfo?.trailDetails?.booking_status?.confirmed
                            ?.status != true
                            ? "statusbx "
                            : "statusbx statuscomplete"
                        }
                      >
                        Payment Confirmed
                      </div>
                      <span>
                        {" "}
                        {moment(
                          bookinginfo?.trailDetails?.booking_status?.confirmed
                            ?.createdAt
                        ).format("DD MMMM h:m")}
                      </span>
                    </li>
                    <li className="statusdiv">
                      <div
                        className={
                          bookinginfo?.trailDetails?.booking_status?.accepted
                            ?.status != true
                            ? "statusbx "
                            : "statusbx statuscomplete"
                        }
                      >
                        Request accepted by carer
                      </div>
                      <span>
                        {moment(
                          bookinginfo?.trailDetails?.booking_status?.accepted
                            ?.createdAt
                        ).format("DD MMMM h:m")}
                      </span>
                    </li>
                    <li className="statusdiv">
                      <div
                        className={
                          bookinginfo?.trailDetails?.booking_status?.assigned
                            ?.status != true
                            ? "statusbx "
                            : "statusbx statuscomplete"
                        }
                      >
                        Assigning Carer to Care Seeker
                        <span>
                          {moment(
                            bookinginfo?.trailDetails?.booking_status?.assigned
                              ?.createdAt
                          ).format("DD MMMM h:m")}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="booking-infobcx">
              <h4>Journey Status</h4>
              <div className="bookinginfo">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Pickup User</Accordion.Header>
                    <Accordion.Body>
                      <div className="statuskeylist">
                        <ul>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.pickup_user?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Pickup User
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.entry_to_airport?.status !=
                                  true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Entery air port
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.check_in_airline_counter
                                    ?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Check in airline counter
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.immigration_check?.status !=
                                  true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Immigration Check
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.security_check?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Security Check
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>In Transit</Accordion.Header>
                    <Accordion.Body>
                      <div className="statuskeylist">
                        <ul>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.reaching_boarding_gate
                                    ?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Reaching Boarding Gate
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.boarding?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Boarding
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.in_flight?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              In Flight
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Arrival</Accordion.Header>
                    <Accordion.Body>
                      <div className="statuskeylist">
                        <ul>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.landed?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Landed
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.baggage_claim?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Baggage Claim
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.customs?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Customs
                            </div>
                          </li>
                          <li>
                            <div className="statuskeydiv active">
                              <img
                                src={
                                  bookinginfo?.trailDetails?.journey_status
                                    ?.in_transit?.exit?.status != true
                                    ? dotCircle
                                    : greencircle
                                }
                              />
                              Exit
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Layout from "./layout/Layout";
import Dashboard from "./pages/Dashboard";
import CareSeeker from "./pages/CareSeeker";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Booking from "./pages/Booking";
import Termandpolicies from "./pages/Termandpolicies";
import About from "./pages/About";
import Policies from "./pages/Policies";
import SubAdminManagement from "./pages/SubAdminManagement";
import Profiledetails from "./pages/Profiledetails";
import Carer from "./pages/Carer";
import Notification from "./pages/Notification";
import Reports from "./pages/Reports";
import Contentmanagement from "./pages/Contentmanagement";
import Setting from "./pages/Setting";
import CarerDetails from "./pages/CarerDetails";
import WifiConnection from "./pages/WifiConnection";
import AddSubAdmin from "./pages/AddSubAdmin";
import Transaction from "./pages/Transaction";
import Chat from "./pages/Chat";
import Offer from "./pages/Offer";
import Commission from "./pages/Commission";
import BookingDetails from "./pages/BookingDetails";
import BookingRequest from "./pages/BookingRequest";
import EditSubAdmin from "./pages/EditSubAdmin";
import WithDraw from "./pages/WithDraw";
import AdminTransaction from "./pages/AdminTransaction";
import BookingCancelRequest from "./pages/BookingCancelRequest";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/care-seeker" element={<CareSeeker />} />
        <Route path="/profile-details/:id" element={<Profiledetails />} />
        <Route path="/carer-details/:id" element={<CarerDetails />} />
        <Route path="/content-management" element={<Contentmanagement />} />
        <Route path="/wifi" element={<WifiConnection />} />
        <Route path="/add-sub-admin" element={<AddSubAdmin />} />
        <Route
          path="/booking-request-from-care-seeker"
          element={<BookingRequest />}
        />
        <Route path="/withdraw" element={<WithDraw />} />
        <Route path="/admin-transaction" element={<AdminTransaction />} />
        <Route
          path="/booking-cancel-request"
          element={<BookingCancelRequest />}
        />

        <Route path="/transaction" element={<Transaction />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/commission" element={<Commission />} />
        <Route path="/carer" element={<Carer />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/booking-details/:id" element={<BookingDetails />} />
        <Route path="/terms" element={<Termandpolicies />} />
        <Route path="/about" element={<About />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/notifications" element={<Notification />} />
        <Route path="/report" element={<Reports />} />
        <Route path="/settings" element={<Setting />} />
        <Route path="/update-subadmin/:id" element={<EditSubAdmin />} />

        <Route path="/sub-admin-management" element={<SubAdminManagement />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

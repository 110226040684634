import React, { useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Addpromotion,
  deletePromotion,
  GetPromotion,
} from "../Redux/Action/PromoCode";
import { adminupload } from "../Redux/Action/CareSeekerAction";
import LoadingOverlay from "react-loading-overlay";
export default function Offer() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true); // Loader state

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const hadeluploadfile = (event) => {
    const file = event.target.files[0];
    let fileinfo = new FormData();
    fileinfo.append("file", file);
    dispatch(adminupload(fileinfo)).then(function (info) {
      formik.setFieldValue("picture", info.payload.url);
    });
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      rate: "",
      picture: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      rate: Yup.string().required("Rate is required"),
      picture: Yup.string().required("Picture required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: (value) => {
      dispatch(Addpromotion(value)).then(function () {
        dispatch(GetPromotion()).then(() => setLoading(false));
        handleClose();
        formik.resetForm();
      });
    },
  });

  //Fetch the promotion

  useEffect(() => {
    dispatch(GetPromotion()).then(() => setLoading(false)); // Stop loader after data is fetched
  }, []);
  const promotionGet = useSelector((state) => state.Promotion.promotionlist);

  const deletepromotionhandelchange = (id) => {
    setLoading(true);
    dispatch(deletePromotion(id)).then(function () {
      dispatch(GetPromotion()).then(() => {
        setLoading(false);
      });
    });
  };

  return (
    <LoadingOverlay active={loading} text="Loading data...">
      <Layout>
        <div className="dashboard_data_wrap">
          <div class="card-wrapper d-flex align-items-center justify-content-between">
            <div className="card-header-left profile_details_header">
              Promo code
            </div>
            <div className="card-header">
              <button className="common-btn" onClick={handleShow}>
                +ADD
              </button>
            </div>
          </div>
          {promotionGet?.map(function (object, i) {
            return (
              <div
                key={i}
                className="promotion-code d-flex justify-content-between align-items-center"
              >
                <div className="promotion-code-b d-flex align-items-center">
                  <img src={object.picture} alt="Promotional Card" />
                  <div>
                    <div className="card-title">{object?.description}</div>
                    <div className="card-offer">Upto ${object?.rate} Off</div>
                  </div>
                </div>
                <div className="delete-button">
                  <i
                    className="fas fa-trash"
                    onClick={() => {
                      deletepromotionhandelchange(object?._id);
                    }}
                  ></i>
                </div>
              </div>
            );
          })}
        </div>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Promo Code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              {/* Banner Image Field */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter title"
                  name="title"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.title &&
                  formik.submitCount > 0 &&
                  formik.errors.title && (
                    <div className="error text-danger">
                      {formik.errors.title}
                    </div>
                  )}
              </div>
              <div className="mb-3">
                <label htmlFor="bannerImage" className="form-label">
                  Banner for Homepage View
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="bannerImage"
                  name="picture"
                  onChange={(event) => {
                    hadeluploadfile(event);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="form-text">
                  Can only upload one image per coupon.
                </div>
                {formik.touched.picture &&
                  formik.submitCount > 0 &&
                  formik.errors.picture && (
                    <div className="error text-danger">
                      {formik.errors.picture}
                    </div>
                  )}
              </div>

              {/* Flat Discount Rate Field */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Flat Discount Rate
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="discountRate"
                  placeholder="Enter discount rate"
                  name="rate"
                  onChange={formik.handleChange}
                  value={formik.values.rate}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.rate &&
                  formik.submitCount > 0 &&
                  formik.errors.rate && (
                    <div className="error text-danger">
                      {formik.errors.rate}
                    </div>
                  )}
              </div>

              {/* Short Text Description Field */}
              <div className="mb-3">
                <label htmlFor="shortDescription" className="form-label">
                  Short Text Description
                </label>
                <textarea
                  className="form-control"
                  id="shortDescription"
                  rows="3"
                  placeholder="Enter a short description"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                ></textarea>
                {formik.touched.description &&
                  formik.submitCount > 0 &&
                  formik.errors.description && (
                    <div className="error text-danger">
                      {formik.errors.description}
                    </div>
                  )}
              </div>

              <div className="modal-footer">
                {/* Close Button */}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                {/* Submit Button */}
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </Layout>
    </LoadingOverlay>
  );
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getDetails } from "../Redux/Action/SubAdmin";

export default function Sidebar() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDetails());
  }, []);
  const admindetails = useSelector((state) => state.SubAdmin.admindetails);
  return (
    <div class="sidebar_menu">
      <ul>
        <ul>
          {(
            admindetails?.permission || [
              {
                id: "dashboard",
                title: "Dashboard",
                url: "/dashboard",
                iconClass: "fas fa-home",
              },
              {
                id: "careseeker",
                title: "Carer Seeker",
                url: "/care-seeker",
                iconClass: "fas fa-user",
              },
              {
                id: "carer",
                title: "Carer",
                url: "/carer",
                iconClass: "fas fa-user",
              },
              {
                id: "booking",
                title: "Booking",
                url: "/booking",
                iconClass: "fas fa-shopping-bag",
              },
              {
                id: "booking",
                title: "Booking Cancel Request",
                url: "/booking-cancel-request",
                iconClass: "fas fa-shopping-bag",
              },

              {
                id: "transaction",
                title: "Transactions",
                url: "/transaction",
                iconClass: "fas fa-user",
              },
              {
                id: "offer",
                title: "Offers",
                url: "/offer",
                iconClass: "fas fa-gift",
              },
              {
                id: "report",
                title: "Reports",
                url: "/report",
                iconClass: "fas fa-user",
              },
              {
                id: "chat",
                title: "Chats",
                url: "/chat",
                iconClass: "fas fa-user",
              },
              {
                id: "content-management",
                title: "Content Management",
                url: "/content-management",
                iconClass: "fas fa-files-o",
              },
              {
                id: "wifi",
                title: "Wifi Controls",
                url: "/wifi",
                iconClass: "fas fa-wifi",
              },
              {
                id: "notifications",
                title: "Notifications",
                url: "/notifications",
                iconClass: "fas fa-bell-o",
              },
              {
                id: "setting",
                title: "Settings",
                url: "/settings",
                iconClass: "fas fa-cog",
              },
              {
                id: "sub-admin-management",
                title: "Sub Admin Management",
                url: "/sub-admin-management",
                iconClass: "fas fa-user-cog",
              },
            ]
          ).map((object, i) => (
            <li key={i}>
              <NavLink to={object.url}>
                <i className={object.icon}></i>
                <span className="text-side-bar">{object.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </ul>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "../Component/Pagination";
import {
  bookinlist,
  cancelbookingrequest,
  cancelreason,
  exportbooking,
  updatebookingcarer,
} from "../Redux/Action/BookingAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Flag from "react-flagkit";
import Contry from "../Component/Contry";
import { careseekerlistinfo } from "../Redux/Action/CareSeekerAction";
import { Modal } from "react-bootstrap";
import Searchable from "react-searchable-dropdown";
import { useFormik } from "formik";
import checkCircle from "../assets/images/check-circle.png";
import cancleCircle from "../assets/images/cancel-circle.png";
import * as Yup from "yup";
export default function BookingRequest() {
  const [loading, setLoading] = useState(true); // Loader state
  const [show, setshow] = useState(false);
  const [id, setid] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [canclerequestshow, setCancleRequestShow] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      bookinlist({
        search: "",
        status: "payment_confirmed",
        page: 1,
        limit: 15,
      })
    );
    dispatch(cancelreason());
  }, []);
  const list = useSelector((state) => state.Booking.booking);
  useEffect(() => {
    dispatch(
      careseekerlistinfo({
        role: "carer",
        limit: 10000,
        page: 1,
      })
    );
  }, []);

  const userList = useSelector((state) => state.careseeker.careseekerlistData);
  const resasonlist = useSelector((state) => state.Booking.resasonlist);

  //resasonlist
  const handleClose = () => {
    setshow(false);
    setid("");
  };

  //updatebookingcarer

  const formik = useFormik({
    initialValues: {
      carer_id: "",
    },
    validationSchema: Yup.object().shape({
      carer_id: Yup.string().required("Carer is required"),
    }),
    onSubmit: (value) => {
      value.booking_id = id;

      dispatch(updatebookingcarer(value)).then(function () {
        dispatch(
          bookinlist({
            search: "",
            status: "payment_confirmed",
            page: 1,
            limit: 15,
          })
        ).then(() => setLoading(false));
        handleClose();
        formik.resetForm();
      });
    },
  });

  const cancelFormik = useFormik({
    initialValues: {
      reason_id: "",
      reason: "",
    },
    validationSchema: Yup.object().shape({
      reason_id: Yup.string().required("Reason id is required"),
      reason: Yup.string().required("Description is required"),
    }),
    onSubmit: (value) => {
      value.booking_id = id;

      dispatch(cancelbookingrequest(value)).then(function () {
        dispatch(
          bookinlist({
            search: "",
            status: "payment_confirmed",
            page: 1,
            limit: 15,
          })
        ).then(() => setLoading(false));
        handleClose();
        formik.resetForm();
      });
    },
  });

  const handelsearch = () => {
    if (search != "") {
      dispatch(
        bookinlist({
          search: search,
          status: "payment_confirmed",
          page: page,
          limit: 10,
        })
      );
    } else {
      dispatch(
        bookinlist({
          search: "",
          status: "payment_confirmed",
          page: page,
          limit: 10,
        })
      );
    }
  };

  const exportcsv = async () => {
    const header = [
      {
        id: "booking_number",
        title: "Booking Number",
      },
      {
        id: "care_seeker",
        title: "Care Seeker",
      },
      {
        id: "booking_status",
        title: "Status",
      },
      {
        id: "createdAt",
        title: "Created At",
      },
    ];
    dispatch(exportbooking({ header, status: "assigned" })).then(function (
      info
    ) {
      window.location.href = info.payload;
    });
  };
  const handlePageClick = (e) => {
    setPage(e.selected + 1);
    dispatch(
      bookinlist({
        search: "",
        status: "payment_confirmed",
        page: e.selected + 1,
        limit: 10,
      })
    );
  };
  const handleCancelBookingClose = () => {
    setCancleRequestShow(false);
    setid("");
  };

  console.log(resasonlist, "resasonlistresasonlist");
  return (
    <Layout>
      <LoadingOverlay text="Loading data...">
        {" "}
        <div class="dashboard_data_wrap">
          <div class="dashbord_cont_wrapper">
            <div class="search-container">
              <div class="search-box">
                <span>
                  <i className="fas fa-search"></i>
                </span>

                <input
                  type="text"
                  placeholder="Search booking id..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <button class="search-button" onClick={handelsearch}>
                  Search
                </button>
              </div>
              <div className="export-options">
                <label htmlFor="export1">Export as :</label>
                <select id="export1" name="export" onChange={exportcsv}>
                  <option value="csv">Please Select</option>
                  <option value="csv">Export .csv</option>
                </select>
              </div>
            </div>
            <div class="user_list_wrap table-responsive">
              <table class="table table-stripd">
                <thead>
                  <th>Booking Number</th>

                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Prefences</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {list?.data?.map(function (object, i) {
                    return (
                      <tr key={i}>
                        <td>{object?.booking_number}</td>

                        <td>
                          <img
                            src={object?.care_seeker?.profile_picture}
                            alt="profile"
                            width="31.33"
                            height="31.33"
                          />

                          {object?.care_seeker != "null" &&
                            object?.care_seeker?.first_name +
                              " " +
                              object?.care_seeker?.last_name}
                        </td>
                        <td>{object?.care_seeker?.email}</td>
                        <td>
                          {object?.care_seeker?.phone?.country_code !=
                            undefined && (
                            <Flag
                              country={Contry(
                                object?.care_seeker?.phone?.country_code
                              )}
                              size={20}
                            />
                          )}
                          {object?.care_seeker?.phone?.country_code +
                            object?.care_seeker?.phone?.number}{" "}
                        </td>
                        <td>---</td>
                        <td>
                          {object?.preferences?.language
                            ?.map((data) => data.name)
                            .join(", ")}
                        </td>
                        <td>
                          {" "}
                          <div className="iconsbcx">
                            {object?.bookingreq.length == 0 ? (
                              <>
                                <div className="common-btn-assign">Assign</div>
                                <div className="checkbxt">
                                  <img
                                    src={checkCircle}
                                    alt="Check"
                                    onClick={() => {
                                      setshow(true);
                                      setid(object?._id);
                                    }}
                                  />
                                  <img
                                    src={cancleCircle}
                                    onClick={() => {
                                      setCancleRequestShow(true);
                                      setid(object?._id);
                                    }}
                                    alt="Cancel"
                                  />
                                </div>
                              </>
                            ) : (
                              object?.bookingreq?.map(
                                (bookingrequestobject, index) =>
                                  bookingrequestobject.approval_status !=
                                  "pending" ? (
                                    <>
                                      <div className="checkbxt" key={index}>
                                        <img
                                          src={checkCircle}
                                          alt="Check"
                                          onClick={() => {
                                            setshow(true);
                                            setid(object?._id);
                                          }}
                                        />
                                        <img
                                          src={cancleCircle}
                                          alt="Cancel"
                                          onClick={() => {
                                            setCancleRequestShow(true);
                                          }}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <div className="common-btn-assign">
                                      Assigned
                                    </div>
                                  )
                              )
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Pagination
                handlePageClick={handlePageClick}
                pageCount={list?.totalItems}
              />
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Carer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              {/* Flat Discount Rate Field */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Carer
                </label>
                <Searchable
                  value=""
                  placeholder="Search Carer" // by default "Search"
                  notFoundText="No result found" // by default "No result found"
                  options={userList?.map((object, i) => ({
                    label: `${object?.first_name} ${object?.last_name}`, // Displayed text
                    value: object?._id || i, // Use object id or index as value
                  }))}
                  onSelect={(value) => {
                    formik.setFieldValue("carer_id", value);
                  }}
                  listMaxHeight={200} //by default 140
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="carer_id"
                />
              </div>
              {formik.touched.carer &&
                formik.submitCount > 0 &&
                formik.errors.carer_id && (
                  <div className="error text-danger">
                    {formik.errors.carer_id}
                  </div>
                )}
              <div className="modal-footer">
                {/* Submit Button */}
                <button type="submit" className=" common-btn">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal show={canclerequestshow} onHide={handleCancelBookingClose}>
          <Modal.Header closeButton>
            <Modal.Title>Cancel The Booking Request</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={cancelFormik.handleSubmit}>
              {/* Flat Discount Rate Field */}
              <div className="mb-3">
                <label htmlFor="discountRate" className="form-label">
                  Carer
                </label>
                <Searchable
                  value=""
                  placeholder="Search Reason" // by default "Search"
                  notFoundText="No result found" // by default "No result found"
                  options={resasonlist?.map((object, i) => ({
                    label: `${object?.reason} `, // Displayed text
                    value: object?._id || i, // Use object id or index as value
                  }))}
                  onSelect={(value) => {
                    cancelFormik.setFieldValue("reason_id", value);
                  }}
                  listMaxHeight={200} //by default 140
                  onChange={cancelFormik.handleChange}
                  onBlur={cancelFormik.handleBlur}
                  name="reason_id"
                />
              </div>
              {cancelFormik.touched.carer &&
                cancelFormik.submitCount > 0 &&
                cancelFormik.errors.reason_id && (
                  <div className="error text-danger">
                    {cancelFormik.errors.reason_id}
                  </div>
                )}
              <div className="mb-3">
                <label htmlFor="shortDescription" className="form-label">
                  Short Text Description
                </label>
                <textarea
                  className="form-control"
                  id="shortDescription"
                  rows="3"
                  placeholder="Enter a short description"
                  name="reason"
                  onChange={cancelFormik.handleChange}
                  value={cancelFormik.values.reason}
                  onBlur={cancelFormik.handleBlur}
                ></textarea>
                {cancelFormik.touched.reason &&
                  cancelFormik.submitCount > 0 &&
                  cancelFormik.errors.reason && (
                    <div className="error text-danger">
                      {cancelFormik.errors.reason}
                    </div>
                  )}
              </div>

              <div className="modal-footer">
                {/* Submit Button */}
                <button type="submit" className=" common-btn">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </LoadingOverlay>
    </Layout>
  );
}
